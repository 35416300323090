import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '部门名称', prop: 'dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '所含小组', prop: 'dept_team_names', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '部门简介', prop: 'dept_brief', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    input: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  }
];
