<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="mBox()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getDepts()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'dept_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @row-dblclick="dbClickJp"
        @getTableData="getDepts"
      >
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { deptAPI } from '@api/modules/department';
import { tableProperties } from '@/views/SystemManagement/DeptManage/dept';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'DeptList',
  components: {
    SearchTable
  },
  data() {
    return {
      dateRange: [],
      tableProperties: tableProperties,
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {}
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/dept_add' || from.path === '/dept_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getDepts();
    },
    // 获取部门信息
    getDepts() {
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange;
      if (startTime) searchForm.start_time = startTime.getTime() / 1000;
      if (endTime) searchForm.end_time = endTime.getTime() / 1000;
      getNoCatch(deptAPI.getDepts, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.initData();
    },
    // 多选获取部门信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 新增
    addRow() {
      this.jump('/dept_add', { perm_id: this.$route.query.perm_id });
    },
    //删除提示
    mBox() {
      if (this.multiSelection.length > 0) {
        this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.delRow();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
            this.$refs.multiTable.clearSelection();
          });
      } else {
        this.$message({
          message: '请选择至少一条',
          type: 'warning'
        });
      }
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.dept_id);
      });
      post(deptAPI.deletDeptByIds, { dept_id_list: ids })
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/dept_edit', { perm_id: this.$route.query.perm_id, form_id: row.dept_id });
    }
  }
};
</script>

<style scoped lang="scss"></style>
